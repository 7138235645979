// extracted by mini-css-extract-plugin
export var mainWrapper = "index-module--main-wrapper--14qVT";
export var section = "index-module--section--2qpnq";
export var list = "index-module--list--2fpCO";
export var about = "index-module--about--2866T";
export var profilePictureFrameOuter = "index-module--profile-picture-frame-outer--273Mq";
export var profilePictureFrameInner = "index-module--profile-picture-frame-inner--3hx_u";
export var profilePicture = "index-module--profile-picture--3G26z";
export var img = "index-module--img--1wnZX";
export var contentWrapper = "index-module--content-wrapper--1GYhA";
export var buttonBox = "index-module--button-box--1qrqL";
export var buttonWrapper = "index-module--button-wrapper--8-Gg8";
export var readMoreButton = "index-module--read-more-button--2AHu3";
export var content = "index-module--content--343Fs";
export var before = "index-module--before--foPpd";
export var after = "index-module--after--3WjD3";
export var contactButton = "index-module--contact-button--2ze1e";
export var expertise = "index-module--expertise--1mvZW";
export var technologies = "index-module--technologies--1MAuw";