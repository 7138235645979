// extracted by mini-css-extract-plugin
export var solidBlack = "heading-module--solid-black--3aXC0";
export var solidWhite = "heading-module--solid-white--3Eye9";
export var gradientPurpleBlue = "heading-module--gradient-purple-blue--wF5tk";
export var gradientPurpleOrange = "heading-module--gradient-purple-orange--2jlDb";
export var gradientYellowBlue = "heading-module--gradient-yellow-blue--2Ce72";
export var gradientYellowPurple = "heading-module--gradient-yellow-purple--1tjA4";
export var gradientYellowOrange = "heading-module--gradient-yellow-orange--LmJ4g";
export var gradientOrangeYellow = "heading-module--gradient-orange-yellow--3rtky";
export var dash = "heading-module--dash--3bh9d";
export var heading = "heading-module--heading--18WZI";